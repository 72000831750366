<template>
  <LoadingDialog v-if="loading" />
  <CancelInterview
    :cancelInterview="cancelScheduledSlot"
    :scheduledSlot="scheduledSlot"
    :isCancelled="interview.isCancelled"
    :text="text"
    :isOwner="isOwner"
    v-else
  />
</template>

<script>
import * as Sentry from '@sentry/browser'
import CancelInterview from '@/components/cancel-interview'
import LoadingDialog from '@/components/LoadingDialog'
import { getScheduledSlot, cancelScheduledSlot } from '@/helpers/firebase-functions'
import { EVENT_NAMES, analyticsEvent } from '@/helpers/analytics'

export default {
  components: {
    CancelInterview,
    LoadingDialog
  },
  props: {
    cancelToken: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      scheduledSlot: {
        isoDate: null,
        startTime: null,
        companyNameJa: null,
        applicantName: null
      },
      interview: {
        isCancelled: null, canclledBy: null
      },
      loading: true,
      text: {
      },
      isOwner: false
    }
  },
  methods: {
    async cancelScheduledSlot (notifyApplicant = true) {
      try {
        await cancelScheduledSlot({ cancelToken: this.cancelToken, notifyApplicant })
      } catch (err) {
        console.error('Problem cancelling interview', err) // eslint-disable-line no-console
        Sentry.captureException(err)
      } finally {
        this.loadData(true, notifyApplicant)
      }
    },
    async loadData (initialCancel, notifyApplicant) {
      this.loading = true
      const decodedToken = decodeURIComponent(this.cancelToken)
      const { data: { scheduledSlot, interview, isOwner } } = await getScheduledSlot({
        cancelToken: decodedToken
      })
      this.scheduledSlot = scheduledSlot
      this.interview = interview
      this.isOwner = isOwner

      const { isCancelled } = this.interview

      if (!isCancelled) {
        this.text = {
          title: this.$t('m.cancelInterview.notCanceledTitle'),
          subtitle: this.$t('m.cancelInterview.notCanceledSubtitle')
        }
      } else if (initialCancel) {
        let title = this.isOwner ? (notifyApplicant ? this.$t('m.cancelInterview.ownerCanceledApplicantNotifiedTitle') : this.$t('m.cancelInterview.ownerCanceledApplicantNotNotifiedTitle')) : this.$t('m.cancelInterview.applicantCanceledTitle')
        let subtitle = this.isOwner ? (notifyApplicant ? this.$t('m.cancelInterview.ownerCanceledApplicantNotifiedSubtitle') : this.$t('m.cancelInterview.ownerCanceledApplicantNotNotifiedSubtitle')) : this.$t('m.cancelInterview.applicantCanceledSubtitle')
        this.text = {
          title,
          subtitle
        }
      } else {
        this.text = {
          title: this.$t('m.cancelInterview.alreadyCanceledTitle'),
          subtitle: this.$t('m.cancelInterview.alreadyCanceledSubtitle')
        }
      }

      this.loading = false
    }
  },
  async mounted () {
    analyticsEvent(EVENT_NAMES.LOADED_CANCEL_INTERVIEW)
    this.loadData()
  }
}
</script>

<style>
</style>
